<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ process }} © {{ new Date().getFullYear() }}

      <span class="d-none d-sm-inline-block"
        >, من برمجة
        <u
          ><a href="https://brmja.com" target="_blank">برمجة دوت كوم</a></u
        ></span
      >
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  data() {
    return {
      process: process.env.VUE_APP_TITLE,
    };
  },
};
</script>
